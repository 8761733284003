import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { convertToQueryParams } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { Method } from "axios";
import { ClientForm } from "../types/ClientForm";
import { ClientListRequest } from "../types/ClientListRequest";
import { ClientData, ClientResponse } from "../types/ClientResponse";

class ClientService {
  public static async getClientList(
    payload?: ClientListRequest
  ): Promise<ListResponse<ClientResponse>> {
    const queryParams = payload ? convertToQueryParams(payload) : "";
    const method: Method = "GET";
    const path = `/iam/clients${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<ClientResponse>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );
    return result;
  }

  public static async getClientDetails(
    clientId: number
  ): Promise<SingleResponse<ClientResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ClientResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/iam/clients/${clientId}`,
      },
      true
    );
    return result;
  }

  public static async createClient(
    data: ClientForm
  ): Promise<SingleResponse<ClientResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ClientResponse>,
      ClientForm
    >(
      {
        method: "POST",
        path: `/iam/clients`,
        data,
      },
      true
    );
    return result;
  }
  public static async updateClient(
    clientId: number,
    data: ClientForm
  ): Promise<SingleResponse<ClientResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ClientResponse>,
      ClientForm
    >(
      {
        method: "PUT",
        path: `/iam/clients/${clientId}`,
        data,
      },
      true
    );
    return result;
  }

  public static async getPricing(): Promise<
    SingleResponse<Record<string, string>>
  > {
    const result = await APIService.jsonRequest<
      SingleResponse<Record<string, string>>,
      unknown
    >(
      {
        method: "GET",
        path: "iam/pricing",
      },
      true
    );

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public static async getConfigType(): Promise<ListResponse<ClientData>> {
    // const result = await APIService.jsonRequest<
    //   SingleResponse<Record<string, string>>,
    //   unknown
    // >(
    //   {
    //     method: "GET",
    //     path: "iam/pricing",
    //   },
    //   true
    // );
    const result = {
      data: [
        {
          type: "INTERNAL_BROKERAGE",
          advanceOrArrears: {
            default: "ADVANCE",
            options: ["ADVANCE", "ARREARS"],
          },
          contractDocument: {
            default: "CONTRACT_PACK",
            options: ["CONTRACT_PACK"],
          },
          includeFees: {
            default: true,
            options: [true, false],
          },
          repaymentTermOption: {
            default: "MONTHLY",
            options: ["MONTHLY", "WEEKLY"],
          },
        },
        {
          type: "EXTERNAL_BROKERAGE",
          advanceOrArrears: {
            default: "ADVANCE",
            options: ["ADVANCE"],
          },
          contractDocument: {
            default: "CONTRACT_PACK",
            options: ["CONTRACT_PACK"],
          },
          includeFees: {
            default: false,
            options: [false],
          },
          repaymentTermOption: {
            default: "MONTHLY",
            options: ["MONTHLY"],
          },
        },
        {
          type: "NORTWEST_COLLEGE",
          advanceOrArrears: {
            default: "ADVANCE",
            options: ["ADVANCE"],
          },
          contractDocument: {
            default: "NORTWEST_COLLEGE",
            options: ["NORTWEST_COLLEGE"],
          },
          includeFees: {
            default: false,
            options: [false],
          },
          repaymentTermOption: {
            default: "WEEKLY",
            options: ["WEEKLY"],
          },
        },
      ],
    };
    return result;
  }
}

export default ClientService;
